import { Button } from "@mantine/core";
import React from "react";

const Hero = () => {
  return (
    <section className="mt-20" data-aos="fade-up">
      <div className="relative overflow-hidden bg-cover bg-no-repeat bg-[50%] bg-[url('https://as2.ftcdn.net/v2/jpg/02/86/76/77/1000_F_286767786_boXM75PDLYIsYWzabZ3fKcM3esv50TNS.jpg')] h-[500px]"></div>
      <div className="w-100 mx-auto px-6 sm:max-w-2xl md:max-w-3xl md:px-12 lg:max-w-5xl xl:max-w-7xl xl:px-32">
        <div className="text-center">
          <div className="block rounded-lg bg-[hsla(0,0%,100%,0.55)] px-6 py-12 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)]  md:py-16 md:px-12 mt-[-170px] backdrop-blur-[30px]">
            <h1 className="mt-2 mb-16 text-5xl font-bold tracking-tight md:text-6xl xl:text-7xl">
              The best offer on the market <br />
              <span className="text-primary">for your business</span>
            </h1>
            <Button variant="light" className="mr-4" size="lg">
              Get started
            </Button>
            <Button size="lg">Learn more</Button>
            {/* <a
            className="mb-2 inline-block rounded bg-primary px-12 pt-4 pb-3.5 text-sm font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] md:mr-2 md:mb-0"
            data-te-ripple-init=""
            data-te-ripple-color="light"
            href="#!"
            role="button"
          >
            Get started
          </a>
          <a
            className="inline-block rounded px-12 pt-4 pb-3.5 text-sm font-medium uppercase leading-normal text-primary transition duration-150 ease-in-out hover:bg-neutral-500 hover:bg-opacity-10 hover:text-primary-600 focus:text-primary-600 focus:outline-none focus:ring-0 active:text-primary-700 dark:hover:bg-neutral-700 dark:hover:bg-opacity-40"
            data-te-ripple-init=""
            data-te-ripple-color="light"
            href="#!"
            role="button"
          >
            Learn more
          </a> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
