import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import "./global.css";
import "@mantine/core/styles.css";
import App from "./App";
import {
  MantineProvider,
  createTheme,
  localStorageColorSchemeManager,
} from "@mantine/core";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import "@mantine/notifications/styles.css";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import store from "./redux/store";
import { Notifications } from "@mantine/notifications";
import "@mantine/dates/styles.css";
import "aos/dist/aos.css";
import { ModalsProvider } from "@mantine/modals";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const colorSchemeManager = localStorageColorSchemeManager({
  key: "sports-color-scheme",
});

const theme = createTheme({
  fontFamily: "Open Sans, sans-serif",
  primaryColor: "blue",
  cursorType: "pointer",
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // staleTime: 60 * 1000,
      staleTime: 0,
    },
  },
});

{
  /* <ReactQueryDevtools initialIsOpen={false} /> */
}

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          <MantineProvider
            theme={theme}
            defaultColorScheme="light"
            colorSchemeManager={colorSchemeManager}
          >
            <ModalsProvider>
              <Notifications position="top-center" />

              <App />
            </ModalsProvider>
          </MantineProvider>
        </QueryClientProvider>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);
