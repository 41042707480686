enum COLORS {
  min3 = "#643A8C",
  min2 = "#2F3B8D",
  min1 = "4B7BB9",
  norm = "green",
  max1 = "#F39C1B",
  max2 = "#F39C1B",
  max3 = "#E41D22",
  max4 = "#E41D22",
  max5 = "#E41D22",
}

export function calcWeight(weight: any, height: any) {
  const weightStandard = (weight / (height * height)) * 10000;

  if (weightStandard < 19) {
    return {
      text: "Tana vazni yetishmovchiligi",
      color: COLORS.min2,
      speed: 150,
    };
  } else if (weightStandard < 19.9) {
    return {
      text: "Tana vazni kamligi",
      color: COLORS.min1,
      speed: 250,
    };
  } else if (weightStandard < 25.9) {
    return {
      text: "Tana vazni me'yorida",
      color: COLORS.norm,
      speed: 350,
    };
  } else if (weightStandard < 27.9) {
    return {
      text: "Tana vazni ortiqcha",
      color: COLORS.max1,
      speed: 450,
    };
  } else if (weightStandard < 30.9) {
    return {
      text: "Birinchi darajali semizlik",
      color: COLORS.max2,
      speed: 500,
    };
  } else if (weightStandard < 35.9) {
    return {
      text: "Ikkinchi darajali semizlik",
      color: COLORS.max3,
      speed: 535,
    };
  } else if (weightStandard < 40.9) {
    return {
      text: "Uchinchi darajali semizlik",
      color: COLORS.max4,
      speed: 570,
    };
  } else {
    return {
      text: "To'rtinchi darajali semizlik",
      color: COLORS.max5,
      speed: 600,
    };
  }
}
