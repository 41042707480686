import { ScrollArea } from "@mantine/core";
import Footer from "pages/Public/Home/components/Footer";
import Navbar from "pages/Public/Home/components/Navbar/Navbar";
import React, { Fragment } from "react";
import { Outlet } from "react-router-dom";

const LandingLayout = () => {
  return (
    <div id="scrollbar1" className="relative">
      {/* Navbar */}
      <Navbar />

      {/* Content */}

      <Outlet />
    </div>
  );
};

export default LandingLayout;
