import http from "services/http";
import { IApi } from "./auth/types";

const postLogin = (data: IApi.Login.Request) => {
  return http({
    method: "POST",
    url: "/auth/login",
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(data),
  });
};

const getAboutMe = () => {
  return http({
    method: "GET",
    url: "/auth/me",
  });
};

const getSports = () => {
  return http({
    method: "GET",
    url: "/sport",
  });
};

const getArticles = (params: any) => {
  return http({
    method: "GET",
    url: "/article",
    params,
  });
};

const getArticleById = (id: string) => {
  return http({
    method: "GET",
    url: `/article/${id}`,
  });
};
const getSportById = (id: string) => {
  return http({
    method: "GET",
    url: `/sport/${id}`,
  });
};

const getForecast = () => {
  return http({
    method: "GET",
    url: "/forecast/calculate",
  });
};

const getDistricts = (params: { regionId: string }) => {
  return http({
    method: "GET",
    url: "/district/select",
    params,
  });
};

const getRegions = () => {
  return http({
    method: "GET",
    url: "/region/select",
  });
};

const getStsByRegion = (params: any) => {
  return http({
    method: "GET",
    url: "/statistic/by-region",
    params,
  });
};

const getStsByHeigSport = (params: any) => {
  return http({
    method: "GET",
    url: "/statistic/height-by-sport",
    params,
  });
};

const getStsByHeigReg = (params: any) => {
  return http({
    method: "GET",
    url: "/statistic/height-by-region",
    params,
  });
};

const getStsBySport = (params: any) => {
  return http({
    method: "GET",
    url: "/statistic/by-sport",
    params,
  });
};

const getSportsSelect = () => {
  return http({
    method: "GET",
    url: "/sport/select",
  });
};

export {
  getStsByHeigReg,
  getSportsSelect,
  getStsBySport,
  getStsByHeigSport,
  getStsByRegion,
  getRegions,
  getDistricts,
  getArticleById,
  getArticles,
  getForecast,
  getSportById,
  getSports,
  postLogin,
  getAboutMe,
};
