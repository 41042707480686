import { Button, Divider, Flex, Table, Text } from "@mantine/core";
import MyLoader from "components/MyLoader";
import config from "config";
import { motion } from "framer-motion";
import { ArrowLeft } from "lucide-react";
import { getSportById } from "modules/api";
import { SingleSport } from "modules/types";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

const elements = [
  { position: 6, mass: 12.011, symbol: "C", name: "Carbon" },
  { position: 7, mass: 14.007, symbol: "N", name: "Nitrogen" },
  { position: 39, mass: 88.906, symbol: "Y", name: "Yttrium" },
  { position: 56, mass: 137.33, symbol: "Ba", name: "Barium" },
  { position: 58, mass: 140.12, symbol: "Ce", name: "Cerium" },
];

const SportId = () => {
  const [data, setData] = useState<SingleSport>();
  const [loading, setLoading] = useState(false);
  const { sportId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (sportId) {
      (async () => {
        try {
          const res = await getSportById(sportId);
          const data: SingleSport = res?.data?.data;
          setData(data);
        } catch (error: any) {}
      })();
    }
  }, []);

  const rows = data?.result?.regions?.length ? (
    data?.result?.regions.map((element, index) => (
      <Table.Tr key={index}>
        <Table.Td>{element.name}</Table.Td>
        <Table.Td>{element.count}</Table.Td>
      </Table.Tr>
    ))
  ) : (
    <Table.Tr>
      <Table.Td>Xozrcha ma'lumot yo'q</Table.Td>
    </Table.Tr>
  );

  // Loader
  if (loading) return <MyLoader />;

  return (
    <motion.div
      className="absolute w-full"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div
        style={{
          width: "100%",
          height: "100vh",
          display: "flex",
          // alignItems: "center",
          justifyContent: "center",
        }}
      >
        <>
          {/* Container for demo purpose */}
          <div className="container mb-20">
            {/* Section: Design Block */}
            <section>
              <div className="sticky top-0 bg-white z-50">
                <Divider mt={30} />
                <Flex className="py-3 mt-2" justify={"space-between"}>
                  <Button
                    onClick={() => navigate(-1)}
                    leftSection={<ArrowLeft size={14} />}
                    variant="outline"
                  >
                    Ortga qaytish
                  </Button>
                  <h1 className="text-3xl font-bold">{data?.name}</h1>
                  <span></span>
                </Flex>
                <Divider mb={30} />
              </div>
              <div className="overflow-hidden rounded-2xl h-[500px] flex justify-center items-center">
                <img
                  src={
                    data?.photo
                      ? `${config.api.baseURL}/storage/files/${data.photo}`
                      : "https://st4.depositphotos.com/17828278/24401/v/450/depositphotos_244011872-stock-illustration-image-vector-symbol-missing-available.jpg"
                  }
                  className="mb-6 w-full rounded-lg shadow-lg dark:shadow-black/20 hover:scale-125 transition-all"
                  alt="image"
                />
              </div>
              {/* <div className="mb-6 flex items-center">
                <img
                  src="https://mdbcdn.b-cdn.net/img/Photos/Avatars/img (23).jpg"
                  className="mr-2 h-8 rounded-full"
                  alt="avatar"
                  loading="lazy"
                />
                <div>
                  <span>
                    {" "}
                    Published <u>15.07.2020</u> by{" "}
                  </span>
                  <a href="#!" className="font-medium">
                    Anna Maria Doe
                  </a>
                </div>
              </div> */}
              <div className="pb-8">
                {/* <ScrollArea h={200} type="auto" offsetScrollbars> */}
                <div className="mb-4 mt-4">
                  <span className="font-bold">Qisqa ma'lumot</span>
                  <p>{data?.shortDescription}</p>
                </div>
                <div>
                  <span className="font-bold">Izoh</span>
                  <p>{data?.description}</p>
                </div>
                {/* </ScrollArea> */}
              </div>

              {/* Statistika */}
              <div className="pb-10">
                <h1 className="mb-6 text-3xl font-bold text-center mt-2">
                  Statistika
                </h1>
                <Table
                  striped
                  highlightOnHover
                  withTableBorder
                  withColumnBorders
                >
                  <Table.Thead>
                    <Table.Tr>
                      <Table.Th>
                        <Text className="text-center font-bold">
                          Viloyatlar
                        </Text>
                      </Table.Th>
                      <Table.Th>
                        <Text className="text-center font-bold">Sonlar</Text>
                      </Table.Th>
                    </Table.Tr>
                  </Table.Thead>
                  <Table.Tbody>{rows}</Table.Tbody>
                </Table>
              </div>
            </section>
            {/* Section: Design Block */}
          </div>
          {/* Container for demo purpose */}
        </>
      </div>
    </motion.div>
  );
};

export default SportId;
