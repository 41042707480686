import { useMutation, useQueryClient } from "@tanstack/react-query";
import { postLogin } from "../../api";
import { notifications } from "@mantine/notifications";
import { AxiosError, AxiosResponse } from "axios";
import { useDispatch, useSelector } from "react-redux";
import { SET_USER_DATA } from "../../../redux/actionTypes";
import { useNavigate } from "react-router-dom";
import { setSession } from "services/store";

export function useLogin() {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { mutate: login, isPending: isLogin } = useMutation({
    mutationFn: postLogin,
    onSuccess: (data: AxiosResponse) => {
      console.log("🚀 ~ useLogin ~ data:", data);
      dispatch({
        type: SET_USER_DATA,
        payload: {
          isAuth: true,
          data: data.data,
        },
      });
      navigate("/");
      setSession({ access: data.data.token });
    },
    onError: (err: any) => {
      console.log("🚀 ~ useLogin ~ err:", err);
      notifications.show({
        color: "red",
        title: err.data?.message,
        message: "Email or Password is wrong",
      });
    },
  });

  return { login, isLogin };
}
