import { Table, Text } from "@mantine/core";
import { SportStatis } from "pages/Public/Statistics/Tabs/TabsHeightSport";
import React, { Fragment } from "react";

const elements = [
  { position: 6, mass: 12.011, symbol: "C", name: "Carbon" },
  { position: 7, mass: 14.007, symbol: "N", name: "Nitrogen" },
  { position: 39, mass: 88.906, symbol: "Y", name: "Yttrium" },
  { position: 56, mass: 137.33, symbol: "Ba", name: "Barium" },
  { position: 58, mass: 140.12, symbol: "Ce", name: "Cerium" },
];

interface Props {
  sportStatistics: SportStatis[];
}

const DashboardHegRgTable = (props: Props) => {
  const { sportStatistics } = props;
  const rows = sportStatistics?.length ? (
    sportStatistics.map((element, inx) => (
      <Table.Tr key={inx}>
        <Table.Td>{inx + 1}</Table.Td>
        <Table.Td>{element.name}</Table.Td>
        <Table.Td>1</Table.Td>
        <Table.Td>2</Table.Td>
        <Table.Td>3</Table.Td>
      </Table.Tr>
    ))
  ) : (
    <Table.Tr>
      <Table.Td>Xozrcha ma'lumotlar yo'q</Table.Td>
    </Table.Tr>
  );

  return (
    <Table mt={20} striped highlightOnHover withTableBorder withColumnBorders>
      {sportStatistics?.length
        ? sportStatistics.map((item, inx) => (
            <Fragment key={inx}>
              {inx === 0 && (
                <Table.Thead>
                  <Table.Tr h={100}>
                    <Table.Th w={80}>
                      <Text className="text-center">№</Text>
                    </Table.Th>
                    <Table.Th w={300}>
                      <Text className="text-center">Viloyat nomi</Text>
                    </Table.Th>
                    {item.result.map((rs, index) => (
                      <Table.Th key={index}>
                        <Text className="text-center">{rs.age} yosh</Text>
                      </Table.Th>
                    ))}
                    {/* <Table.Th colSpan={3}>
                    <Text className="text-center">Symbol</Text>
                  </Table.Th> */}
                  </Table.Tr>
                  {/* <Table.Tr>
                    {item.result.map((rs, index) => (
                      <Fragment key={index}>
                        <Table.Th>
                          <Text className="text-center">Min</Text>
                        </Table.Th>
                      </Fragment>
                    ))}
                  </Table.Tr> */}
                </Table.Thead>
              )}
              <Table.Tbody>
                <Table.Tr key={inx}>
                  <Table.Td>
                    <Text className="text-center">{inx + 1}</Text>
                  </Table.Td>
                  <Table.Td>
                    <Text className="text-center">{item.name}</Text>
                  </Table.Td>
                  {item.result.map((rs, ind) => (
                    <Fragment key={ind}>
                      <Table.Td bg={`${ind === 0 ? "#6AD72D70" : ""}`}>
                        <Text className="text-center">{rs.forecastHeight}</Text>
                      </Table.Td>
                    </Fragment>
                  ))}
                </Table.Tr>
              </Table.Tbody>
            </Fragment>
          ))
        : ""}
      {/* <Table.Thead>
        <Table.Tr>
          <Table.Th rowSpan={2}>
            <Text className="text-center">№</Text>
          </Table.Th>
          <Table.Th rowSpan={2}>
            <Text className="text-center">Sport nomi</Text>
          </Table.Th>
          <Table.Th colSpan={3}>
            <Text className="text-center">Symbol</Text>
          </Table.Th>
        </Table.Tr>
        <Table.Tr>
          <Table.Th>
            <Text className="text-center">Min</Text>
          </Table.Th>
          <Table.Th>
            <Text className="text-center">Max</Text>
          </Table.Th>
          <Table.Th>
            <Text className="text-center">Avg</Text>
          </Table.Th>
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>{rows}</Table.Tbody> */}
    </Table>
  );
};

export default DashboardHegRgTable;
