import {
  Badge,
  Box,
  Button,
  Collapse,
  Divider,
  Flex,
  Group,
  ScrollArea,
  Table,
  Text,
  TextInput,
  rem,
} from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import { isNotEmpty, useForm } from "@mantine/form";
import { useScrollIntoView, useWindowScroll } from "@mantine/hooks";
import { notifications } from "@mantine/notifications";
import dayjs from "dayjs";
import { Calendar } from "lucide-react";
import { getForecast } from "modules/api";
import { Forecast, ForecastForm, Forecasted } from "modules/types";
import React, { useEffect, useState } from "react";
import ReactSpeedometer from "react-d3-speedometer";
import { calcWeight } from "utils/calcWeight";
import { convertForecast } from "utils/calculateForecast";

const ForecastCalc = () => {
  const form = useForm({
    mode: "uncontrolled",
    initialValues: {
      birthDate: "",
      height: "",
      weight: "",
    },

    validate: {
      birthDate: isNotEmpty(""),
      height: isNotEmpty(""),
      weight: isNotEmpty(""),
    },
  });
  const [value, setValue] = useState<any>(null);
  const icon = <Calendar style={{ width: rem(18), height: rem(18) }} />;
  const [forecast, setForecast] = useState<Forecast[]>([]);
  const [valueSpeed, setValueSpeed] = useState(100);
  const [textSpeed, setTextSpeed] = useState(" ");
  const [forecastedData, setForecastedData] = useState<Forecasted[]>([]);
  const [isOpened, setIsOpened] = useState(false);
  const [resultWeight, setResultWeight] = useState<{
    text: string;
    color: string;
  } | null>(null);
  const [scroll, scrollTo] = useWindowScroll();

  const rows = forecastedData?.length ? (
    forecastedData.map((element, index) => (
      <Table.Tr key={index}>
        <Table.Td>
          <Text className="text-center">{element.age}</Text>
        </Table.Td>
        <Table.Td>
          <Text className="text-center">{element.height.toFixed()} (sm)</Text>
        </Table.Td>
      </Table.Tr>
    ))
  ) : (
    <Table.Tr>
      <Table.Td>No data</Table.Td>
    </Table.Tr>
  );

  useEffect(() => {
    (async () => {
      try {
        const res = await getForecast();
        const data: Forecast[] = res?.data?.data;
        setForecast(data);
        // console.log("🚀 ~ data:", data);
      } catch (error: any) {
        console.log("🚀 ~ error:", error);
      }
    })();
  }, []);

  const onSubmit = (values: ForecastForm) => {
    console.log("🚀 ~ onSubmit ~ values:", values);
    const birthDate = value;
    const currentDate = dayjs();

    // Parsing the birth date
    const parsedBirthDate = dayjs(birthDate, "DD/MM/YYYY");

    // Calculating the difference in years
    const age = currentDate.diff(parsedBirthDate, "year");
    console.log("🚀 ~ onSubmit ~ age:", age);
    if (age >= 1) {
      setForecastedData(
        convertForecast(forecast, Number(values?.height), Number(age))
      );
      const result = calcWeight(values.weight, values.height);
      setResultWeight(result);
      setIsOpened(true);
      setValueSpeed(result.speed);
      setTextSpeed("To'rtinchi darajali semizlik");
      //   setTimeout(() => {
      //     window.scrollTo({
      //       top: document.documentElement.scrollHeight,
      //       behavior: "smooth",
      //     });
      //   }, 100);
    } else {
      notifications.show({
        title: "Yosh 1 dan katta bo'lishi kerak",
        message: null,
        color: "red",
      });
    }
  };

  const clearHandler = () => {
    form.reset();
    setResultWeight(null);
    setIsOpened(false);
    setValue(null);
    setValueSpeed(100);
    setTimeout(() => {
      setForecastedData([]);
    }, 100);
  };
  return (
    <div className="container mx-auto px-3 mb-20">
      <Divider mt={30} />
      <h2 className="py-3 text-center text-3xl font-bold">Vazn bashorati</h2>
      <Divider mb={30} />
      <Box>
        <form onSubmit={form.onSubmit((values) => onSubmit(values))}>
          <Flex align={"end"} justify={"space-between"}>
            <Flex className="w-full">
              <TextInput
                type="date"
                className="w-full px-3"
                label="To'g'ilgan kun"
                placeholder="Iltimos, to'g'ilgan kuningizni tanlang"
                {...(form.getInputProps("birthDate"),
                {
                  // value,
                  onChange: (event: any) => {
                    const e = event?.target?.value;
                    console.log("🚀 ~ ForecastCalc ~ e:", e);
                    setValue(e);
                    form.setFieldValue(
                      "birthDate",
                      dayjs(e).format("DD-MM-YYYY")
                    );
                  },
                  error: form.errors.birthDate,
                })}
              />
              {/* <DatePickerInput
                className="w-full"
                // clearable
                leftSection={icon}
                leftSectionPointerEvents="none"
                label="To'g'ilgan kun"
                placeholder="Iltimos, to'g'ilgan kuningizni tanlang"
                {...(form.getInputProps("birthDate"),
                {
                  value,
                  onChange: (e) => {
                    setValue(e);
                    form.setFieldValue(
                      "birthDate",
                      dayjs(e).format("DD-MM-YYYY")
                    );
                  },
                  error: form.errors.birthDate,
                })}
              /> */}
              <TextInput
                type="number"
                className="w-full px-3"
                label="Bo'y (sm)"
                placeholder="Iltimos, bo'yingizni kiriting"
                {...form.getInputProps("height")}
              />
              <TextInput
                type="number"
                className="w-full mr-3"
                label="Vazn (kg)"
                placeholder="Iltimos, vazningizni kiriting"
                {...form.getInputProps("weight")}
              />
            </Flex>

            <Button
              variant="light"
              onClick={clearHandler}
              type="reset"
              w={200}
              className="mr-3"
            >
              Tozalash
            </Button>
            <Button type="submit" w={200}>
              Bashorat
            </Button>
          </Flex>
        </form>
        {/* <ReactSpeedometer minValue={100} maxValue={600} /> */}
        <Box mb={240}>
          <Flex justify={"center"} mt={10}>
            <div
              style={{
                width: "500px",
                height: "300px",
              }}
              className="mt-20"
            >
              <ReactSpeedometer
                fluidWidth={true}
                minValue={100}
                maxValue={600}
                value={valueSpeed}
                currentValueText={"Tana vazni indeksi"}
                customSegmentLabels={[
                  {
                    text: "Yetishmovchiligi",
                    color: "#555",
                  },
                  {
                    text: "Kamligi",
                    color: "#555",
                  },
                  {
                    text: "Me'yorida",
                    color: "#555",
                  },
                  {
                    text: "Ortiqcha",
                    color: "#555",
                  },
                  {
                    text: "Semizlik",
                    color: "#555",
                  },
                ]}
                needleColor="steelblue"
                textColor={"#AAA"}
              />
            </div>
          </Flex>
          <Collapse in={isOpened}>
            <Flex justify={"center"} mt={30}>
              <Badge color={resultWeight?.color} size="lg">
                {resultWeight?.text}
              </Badge>
            </Flex>
            <Table
              mt={20}
              highlightOnHover
              withColumnBorders
              striped
              withTableBorder
            >
              <Table.Thead>
                <Table.Tr>
                  <Table.Th>
                    <Text className="text-center">Yosh</Text>
                  </Table.Th>
                  <Table.Th>
                    <Text className="text-center">Bo'y</Text>
                  </Table.Th>
                </Table.Tr>
              </Table.Thead>
              <Table.Tbody>{rows}</Table.Tbody>
            </Table>
          </Collapse>
        </Box>
      </Box>
    </div>
  );
};

export default ForecastCalc;
