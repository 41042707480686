import React from "react";
import { motion } from "framer-motion";

const Blog = () => {
  return (
    <motion.div
      className="absolute w-full"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div
        style={{
          width: "100%",
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <h1>Blog</h1>
      </div>
    </motion.div>
  );
};

export default Blog;
