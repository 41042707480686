import { routes } from "./Routes";

export const getPublicRoutes = () => {
  return routes.filter(
    (item: any) => !item.config.isAuth && !item.config.isLandingLayout
  );
};

export const getPrivateRoutes = () => {
  return routes.filter((item) => item.config.isAuth);
};
