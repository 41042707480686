import { Forecast, Forecasted } from "modules/types";

export function convertForecast(
  forecasts: Forecast[],
  height: number,
  age: number
) {
  const convertedForecast: Forecasted[] = [];

  for (let item = 0; item < forecasts.length; item++) {
    const element = forecasts[item];

    if (age <= element.age) {
      const newHeight =
        age === element.age
          ? height
          : (convertedForecast[convertedForecast.length - 1].height /
              forecasts[item - 1].percentForecast) *
            element.percentForecast;

      const newForecast: Forecasted = {
        age: element.age,
        height: newHeight,
      };

      convertedForecast.push(newForecast);
    }
  }

  return convertedForecast;
}
