import { Box, Button, Divider, Flex, Group, Tabs } from "@mantine/core";
import { ArrowLeft, Settings } from "lucide-react";
import { useNavigate } from "react-router-dom";
import TabSport from "./Tabs/TabSport";
import TabRegion from "./Tabs/TabRegion";
import TabsHeightSport from "./Tabs/TabsHeightSport";
import TabsHeightReg from "./Tabs/TabsHeightReg";

const Statistics = () => {
  const navigate = useNavigate();

  return (
    <Box className="container mx-auto">
      <Group grow className="py-3 mt-2" justify={"space-between"}>
        <Box>
          <Button
            onClick={() => navigate(-1)}
            leftSection={<ArrowLeft size={14} />}
            variant="outline"
          >
            Ortga qaytish
          </Button>
        </Box>
        <Box className="text-center">
          <h1 className="text-3xl font-bold">Statistika</h1>
        </Box>
        <Box></Box>
      </Group>
      <Divider />

      <Tabs defaultValue="gallery">
        <Tabs.List grow>
          <Tabs.Tab value="gallery" leftSection={<Settings size={14} />}>
            Sport
          </Tabs.Tab>
          <Tabs.Tab value="messages" leftSection={<Settings size={14} />}>
            Viloyat
          </Tabs.Tab>
          <Tabs.Tab value="settings" leftSection={<Settings size={14} />}>
            Bo'yi (sport)
          </Tabs.Tab>
          <Tabs.Tab value="region" leftSection={<Settings size={14} />}>
            Bo'yi (viloyat)
          </Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="gallery">
          <Box mt={20}>
            <TabSport />
          </Box>
        </Tabs.Panel>

        <Tabs.Panel value="messages">
          <Box mt={20}>
            <TabRegion />
          </Box>
        </Tabs.Panel>

        <Tabs.Panel value="settings">
          <Box mt={20}>
            <TabsHeightSport />
          </Box>
        </Tabs.Panel>

        <Tabs.Panel value="region">
          <Box mt={20}>
            <TabsHeightReg />
          </Box>
        </Tabs.Panel>
      </Tabs>
    </Box>
  );
};

export default Statistics;
