import {
  Box,
  Button,
  Card,
  Flex,
  Grid,
  Group,
  Image,
  Text,
} from "@mantine/core";
import React from "react";
// import img_src from "../../../../../assets/partners/Abdulaziz.jpg";
import { Link } from "react-router-dom";

const ProjectPartners = () => {
  return (
    <div id="partners" className="container px-3 mx-auto overflow-hidden mt-24">
      <h2 className="py-3 text-center text-3xl font-bold">Loyiha a'zolari</h2>
      <Grid gutter="lg">
        <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
          <Card
            shadow="sm"
            padding="lg"
            radius="md"
            withBorder
            // className="h-[400px]"
          >
            <Card.Section className="overflow-hidden">
              <Image
                className="h-[350px] hover:scale-125 transition-all"
                src={
                  "https://res.cloudinary.com/apple-angren/image/upload/v1716909873/a3edqd6fuo2irmwofuwg.jpg"
                }
                // height={160}
                alt="Norway"
              />
            </Card.Section>

            <Flex
              direction={"column"}
              justify={"space-between"}
              className="h-full"
            >
              <Box>
                <Group justify="space-between" mt="md" mb="xs">
                  <Text fw={500}>Xamidjonov Abdulaziz Usubjon o‘g‘li</Text>
                </Group>

                <Text size="sm" c="dimmed">
                  Jismoniy tarbiya va sport ilmiy tadqiqotlar instituti
                  doktorant (DSc), 2021-yil “Belbog‘li kurash sport turiga
                  bolalarni saralash tizimi va mazmunini takomillashtirish”
                  mavzusidagi pedagogika fanlari bo‘yicha falsafa doktori (PhD)
                  dissertatsiyasini muvaffaqiyatli himoya qilgan. Jismoniy
                  tarbiya va sport ilmiy tadqiqotlar instituti 2-bosqich
                  doktoranti (DSc). Hozirda “Sport kurashi turlariga saralash va
                  erta yo‘naltirishning metodologik asoslari” ilmiy izlanishlar
                  olib bormoqda, Belbog‘li kurash sport turi bo‘yicha Xalqaro
                  toifadagi sport ustasi. Faoliyati davomida 5 dan ortiq
                  darslik, o‘quv qo‘llanma va uslubiy qo‘llanmalarni nashr
                  ettirdi. Xususan: “Jismoniy tarbiya va sport nazariyasi va
                  uslubiyati” nomli o‘quv qo‘llanma hamda “Sport kurashi
                  turlariga saralash va erta yo‘naltirishning metodologik
                  asoslari” nomli monografiyalar nashr qildirdi. Bundan tashqari
                  O‘zbekiston Oliy ta’lim, fan va innovatsiyalar Vazirligi
                  huzuridagi, Innovatsiya rivojlantirish agentligi tomonidan
                  e’lon qilingan ALM-202306161313-sonli “Sport ta’lim
                  muassasalarida seleksiya, saralash, bashorat qilish va oliy
                  sport yutuqlarini rivojlanish monitoringini joriy qilish uchun
                  raqamli texnologiyalarni yaratish” nomli innovatsion loyiha
                  rahbari sifatida faoliyat olib bormoqda.
                  <br />
                  Qabul vaqtlari: dushanba - juma (14:00 - 17:00) <br /> Telefon
                  raqam: +998 99 396 50 36 <br /> Email:
                  abdulaziz19931910@gmail.ru
                </Text>
              </Box>

              {/* <Link to={`/`}>
                <Button color="blue" fullWidth mt="md" radius="md">
                  Batafsil
                </Button>
              </Link> */}
            </Flex>
          </Card>
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
          <Card
            shadow="sm"
            padding="lg"
            radius="md"
            withBorder
            // className="h-[400px]"
          >
            <Card.Section className="overflow-hidden">
              <Image
                className="h-[350px] hover:scale-125 transition-all"
                src={
                  "https://res.cloudinary.com/apple-angren/image/upload/v1716909873/bip3rkyqrrlylkpjm6tg.jpg"
                }
                // height={160}
                alt="Norway"
              />
            </Card.Section>

            <Flex
              direction={"column"}
              justify={"space-between"}
              className="h-full"
            >
              <Box>
                <Group justify="space-between" mt="md" mb="xs">
                  <Text fw={500}>Xolmurodov Lazizbek Zarifboyevich</Text>
                </Group>

                <Text size="sm" c="dimmed">
                  Ilmiy ishlar va innovatsiyalar bo'yicha direktor o'rinbosari,
                  pedagogika fanlari bo'yicha falsafa doktori (PhD) dotsent.
                  Faoliyati davomida kafedraning o`quv, ilmiy-uslubiy ishlarini
                  samarali tashkil qilishda me’yoriy-xuquqiy hujjatlarni ishlab
                  chiqish, oltmishtadan ortiq ilmiy-uslubiy ishlar muallifi, shu
                  jumladan; bitta yakka muallifda o`quv-qo`llanma, oltita
                  xammualiflikda uslubiy qo`llanma, bitta “O`quv uslubiy
                  majmua”, amaliy fundamintal yo`nalishdagi ilmiy loyihada
                  “Kichik ilmiy xodim” lavozimida hamda Respublika va Xalqaro
                  ilmiy-amaliy anjumanlarida o`zining ilmiy maqola va tezislari
                  bilan ishtirok etib kelmoqda. <br /> Qabul vaqtlari:
                  dushanba-juma (14:00- 17:00) <br /> Telefon raqam: +998 93 761
                  80 00 <br /> Email: lazizbekxol1986@gmail.com
                </Text>
              </Box>

              {/* <Link to={`/`}>
                <Button color="blue" fullWidth mt="md" radius="md">
                  Batafsil
                </Button>
              </Link> */}
            </Flex>
          </Card>
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
          <Card
            shadow="sm"
            padding="lg"
            radius="md"
            withBorder
            // className="h-[400px]"
          >
            <Card.Section className="overflow-hidden">
              <Image
                className="h-[350px] hover:scale-125 transition-all"
                src={
                  "https://res.cloudinary.com/apple-angren/image/upload/v1716996911/jjl1wwmj5augkqgpfbqj.png"
                }
                // height={160}
                alt="Norway"
              />
            </Card.Section>

            <Flex
              direction={"column"}
              justify={"space-between"}
              className="h-full"
            >
              <Box>
                <Group justify="space-between" mt="md" mb="xs">
                  <Text fw={500}>Xamidjonov Abdulaziz Usubjon o‘g‘li</Text>
                </Group>

                <Text size="sm" c="dimmed">
                  Xamidjonov Abdunazar Usubjon o’g’li 2003-yil 22-avgustda
                  Namangan viloyati To‘raqo‘rg‘on tumanida tug‘ilgan. 2023-yilda
                  nashrdan chiqqan Sport faoliyati talabalari uchun umumiy
                  fanlar bo’yicha “TESTLAR TO’PLAMI” va amaliy topshiriqlar
                  I-II-jilda nashrda chop etilgan. 2023-yilda “Belbog’li
                  kurashchilarning jismoniy sifatlarini rivojlantirish” o‘quv
                  qo‘llanmasi O‘zbekiston Respublikasi Oliy va o‘rta maxsus
                  ta‘lim vazirligi va Vazirlar maxkamasi tomonidan guvoxnoma
                  (grif) berilib, nashr etishga ruxssat etilgan. 2023-yilda
                  tashkil etilgan, Mustaqil Davlatlar Hamdo’stligi (MDH)
                  miqyosidagi olimlar va izlanuvchilar uchun e’lon qilingan
                  “Scientific Academy”, “FAOL TADQIQOTCHI” naminatsiyasi g‘olibi
                  bo‘lib, ko‘krak nishon va qimmat baho sovg‘a bilan
                  taqdirlandi. 2023-yilning 4-dekabrdagi 516-son buyrug’i
                  asosan, 2023-2024-o’quv yili uchun respublika oliy ta’lim
                  muassasalari bakalavriyatining oxirgi ikki kursidatahsil
                  olayotgan talabalar uchun ta’sis etilgan davlat stipendiyalari
                  tanlovida ishtirok etib, “PAHLAVON MAHMUD” nomli davlat
                  stipendiyasi sohibi bo’lgan. 2023-yilda Namangan davlat
                  universiteti va Jismoniy tarbiya va sport ilmiy tadqiqotlar
                  instituti bilan hamkorlikda “Sport ta’lim muassasalarida
                  seleksiya, saralash, bashorat qilish va oliy sport yutuqlarini
                  rivojlanish monitoringini joriy qilish uchun raqamli
                  texnologiyalarni yaratish” nomli grant loyihada hamda
                  “Paralimpiya sport musobaqalariga zaxira sportchilar
                  tayyorlash uchun kinematik va psixo-fiziologik tavsiflari
                  bo‘yicha saralash tizimini ishlab chiqish” innovatsion
                  loyihada texnik hodim sifatida ilmiy tadqiqot olib
                  bormoqdaman. <br /> Qabul vaqtlari: dushanba - juma (14:00 -
                  17:00) <br />
                  Telefon raqam: +998 94 781 80 11 <br /> Email:
                  abdulanazar8669@gmail.ru
                </Text>
              </Box>

              {/* <Link to={`/`}>
                <Button color="blue" fullWidth mt="md" radius="md">
                  Batafsil
                </Button>
              </Link> */}
            </Flex>
          </Card>
        </Grid.Col>
      </Grid>
    </div>
  );
};

export default ProjectPartners;
