import { ActionIcon } from "@mantine/core";
import { useWindowScroll } from "@mantine/hooks";
import { motion } from "framer-motion";
import { ArrowUp } from "lucide-react";
import { useNavigate } from "react-router-dom";
import Articles from "./components/Articles";
import Cards from "./components/Cards";
import Footer from "./components/Footer";
import ForecastCalc from "./components/ForecastCalc";
import Hero from "./components/Hero";
import ProjectPartners from "./components/ProjectPartners";
import Partners from "./components/Partners";

const Home = () => {
  const navigate = useNavigate();
  const [scroll, scrollTo] = useWindowScroll();
  return (
    <motion.div
      className="absolute w-full"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div>
        <div
          className={`opacity-${scroll.y >= 369 ? "1" : "0"} transition-all`}
          style={{ position: "fixed", bottom: "20px", right: "20px" }}
        >
          <ActionIcon
            onClick={() => scrollTo({ y: 0 })}
            variant="filled"
            size="xl"
            radius="xl"
            aria-label="Settings"
          >
            <ArrowUp />
          </ActionIcon>
        </div>

        {/* Hero Section */}
        <Hero />

        {/* Cards */}
        <Cards />

        {/* Articles */}
        <Articles />

        <ProjectPartners />

        <Partners />

        {/* News */}
        {/* <News /> */}

        {/* ForecastCalc */}
        <ForecastCalc />

        {/* Footer */}
        <Footer />
      </div>
    </motion.div>
  );
};

export default Home;
