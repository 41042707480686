import {
  Box,
  Button,
  Card,
  Divider,
  Flex,
  Grid,
  Group,
  Image,
  Text,
} from "@mantine/core";
import config from "config";
import { getSports } from "modules/api";
import { SportData } from "modules/types";
import { Fragment, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

const arr = [
  {
    name: "1",
  },
  {
    name: "1",
  },
  {
    name: "1",
  },
  {
    name: "1",
  },
];

const Cards = () => {
  // const { error, isLoading, sports } = useSports();
  const [data, setData] = useState<SportData[]>([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  // const data = sports?.data?.data;

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const res = await getSports();
        const data = res?.data?.data;
        setData(data);
        console.log("🚀 ~ Cards ~ data:", data);
      } catch (error) {
        console.log("🚀 ~ error:", error);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  return (
    <Fragment>
      <div
        id="sports"
        className="container px-3 mx-auto overflow-hidden mt-24"
        // data-aos="zoom-in"
      >
        <div className="h-full">
          {data?.length ? (
            data.map((item, index) =>
              item?.sport?.length ? (
                <div key={index}>
                  <Divider mt={30} />
                  <h2 className="py-3 text-center text-3xl font-bold">
                    {item?.type}
                  </h2>
                  <Divider mb={30} />
                  <Grid gutter="lg">
                    {item?.sport?.length
                      ? item.sport.map((item, index) => (
                          <Grid.Col
                            key={index}
                            span={{ base: 12, md: 6, lg: 3 }}
                          >
                            <Card
                              shadow="sm"
                              padding="lg"
                              radius="md"
                              withBorder
                              // className="h-[400px]"
                            >
                              <Card.Section className="overflow-hidden">
                                <Image
                                  className="h-[250px] hover:scale-125 transition-all"
                                  src={
                                    item?.photoPath
                                      ? `${config.api.baseURL}/storage/files/${item.photoPath}`
                                      : "https://as2.ftcdn.net/v2/jpg/02/86/76/77/1000_F_286767786_boXM75PDLYIsYWzabZ3fKcM3esv50TNS.jpg"
                                  }
                                  // height={160}
                                  alt="Norway"
                                />
                              </Card.Section>

                              <Flex
                                direction={"column"}
                                justify={"space-between"}
                                className="h-full"
                              >
                                <Box>
                                  <Group
                                    justify="space-between"
                                    mt="md"
                                    mb="xs"
                                  >
                                    <Text fw={500}>{item.name}</Text>
                                  </Group>

                                  <Text
                                    size="sm"
                                    c="dimmed"
                                    className="line-clamp-3"
                                  >
                                    {item.shortDescription}
                                  </Text>
                                </Box>

                                <Link to={`${item.id}`}>
                                  <Button
                                    color="blue"
                                    fullWidth
                                    mt="md"
                                    radius="md"
                                  >
                                    Batafsil
                                  </Button>
                                </Link>
                              </Flex>
                            </Card>
                          </Grid.Col>
                        ))
                      : ""}
                  </Grid>
                </div>
              ) : (
                ""
              )
            )
          ) : (
            <h1>No sports</h1>
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default Cards;
