import { Loader } from "@mantine/core";
import React from "react";

const MyLoader = () => {
  return (
    <div className="w-[100%] h-[100vh] flex justify-center items-center">
      <Loader />
    </div>
  );
};

export default MyLoader;
