import {
  Anchor,
  AppShell,
  Avatar,
  Badge,
  Burger,
  Button,
  Group,
  HoverCard,
  Radio,
  Skeleton,
  Stack,
  Text,
  UnstyledButton,
} from "@mantine/core";
import { useDisclosure, useWindowScroll } from "@mantine/hooks";
import { MantineLogo } from "@mantinex/mantine-logo";
import { motion } from "framer-motion";
import { BarChart4, ChevronDown } from "lucide-react";
import { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";

const Navbar = () => {
  const navigate = useNavigate();
  const [mobileOpened, { toggle: toggleMobile }] = useDisclosure();
  const [scroll, scrollTo] = useWindowScroll();
  const [checkedNum, setCheckedNum] = useState(0);

  return (
    <motion.div
      className="absolute w-full"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <AppShell
        data-aos="fade-down"
        header={{ height: 60 }}
        navbar={{
          width: 300,
          breakpoint: "sm",
          collapsed: { mobile: !mobileOpened, desktop: true },
        }}
        padding="md"
      >
        <AppShell.Header>
          <Group h="100%" px="md">
            <Group justify="space-between" style={{ flex: 1 }}>
              <MantineLogo size={30} onClick={() => navigate("/")} />
              <Burger
                opened={mobileOpened}
                onClick={toggleMobile}
                hiddenFrom="sm"
                size="sm"
              />

              <Group ml="xl" gap={0} visibleFrom="sm">
                {/* <Badge>
                  Scroll position x: {scroll.x}, y: {scroll.y}
                </Badge> */}
                <HoverCard
                  width={320}
                  shadow="md"
                  withArrow
                  // openDelay={200}
                  // closeDelay={400}
                >
                  <HoverCard.Target>
                    <UnstyledButton className="mr-7 flex items-center">
                      Sportlar <ChevronDown className="ml-1" size={14} />
                    </UnstyledButton>
                  </HoverCard.Target>
                  <HoverCard.Dropdown>
                    <Radio.Group defaultValue={`v${checkedNum}`}>
                      <Stack>
                        <Radio
                          onClick={() => scrollTo({ y: 861 })}
                          onChange={() => setCheckedNum(1)}
                          value="v1"
                          label="Sport o'yinlari"
                        />
                        <Radio
                          onClick={() => scrollTo({ y: 1875 })}
                          onChange={() => setCheckedNum(2)}
                          value="v2"
                          label="Qiyin-muvofiqlashtiruvchi sport turlari"
                        />
                        <Radio
                          onClick={() => scrollTo({ y: 2437 })}
                          onChange={() => setCheckedNum(3)}
                          value="v3"
                          label="Yakka kurash sport turlari"
                        />
                        <Radio
                          onClick={() => scrollTo({ y: 3905 })}
                          onChange={() => setCheckedNum(4)}
                          value="v4"
                          label="Siklik sport turlari"
                        />
                        <Radio
                          onClick={() => scrollTo({ y: 4459 })}
                          onChange={() => setCheckedNum(5)}
                          value="v5"
                          label="Kuchli sport turlari"
                        />
                      </Stack>
                    </Radio.Group>
                  </HoverCard.Dropdown>
                </HoverCard>

                <UnstyledButton
                  className="mr-7"
                  onClick={() => scrollTo({ y: 5019 })}
                >
                  Maqolalar
                </UnstyledButton>
                <UnstyledButton
                  className="mr-7"
                  onClick={() => scrollTo({ y: 6838 })}
                >
                  Loyiha a'zolari
                </UnstyledButton>
                <UnstyledButton
                  className="mr-7"
                  onClick={() => scrollTo({ y: 8018 })}
                >
                  Hamkorlar
                </UnstyledButton>
                <UnstyledButton
                  className="mr-7"
                  onClick={() => scrollTo({ y: 8525 })}
                >
                  Bashorat
                </UnstyledButton>
                <NavLink to={"statistics"}>
                  <Button
                    color="green"
                    variant="light"
                    rightSection={<BarChart4 size={14} />}
                    className="mr-7"
                  >
                    Statistika
                  </Button>
                </NavLink>
                {/* <Button
                variant="light"
                className="mr-3"
                onClick={() => navigate("/signup")}
              >
                Sign up
              </Button> */}
                {/* <Button onClick={() => navigate("/login")}>Login</Button> */}
              </Group>
            </Group>
          </Group>
        </AppShell.Header>
        <AppShell.Navbar p="md">
          Navbar
          {Array(15)
            .fill(0)
            .map((_, index) => (
              <Skeleton key={index} h={28} mt="sm" animate={false} />
            ))}
        </AppShell.Navbar>
      </AppShell>
    </motion.div>
  );
};

export default Navbar;
