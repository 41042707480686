import Blog from "pages/Public/Blog";
import Contacts from "pages/Public/Contacts";
import Support from "pages/Public/Support";
import Login from "pages/Public/Login";
import LandingLayout from "ui/LandingLayout/LandingLayout";
import Dashboard from "pages/Private/Dashboard";
import Home from "pages/Public/Home";
import Search from "pages/Private/Search";
import Insights from "pages/Private/Insights";
import Docs from "pages/Private/Docs";
import SportId from "pages/Public/SportId";
import ArticleId from "pages/Public/ArticleId";
import Statistics from "pages/Public/Statistics";

interface Routes {
  key: string;
  name: string;
  path: string;
  component: React.FC;
  children?: {
    key: string;
    name: string;
    path: string;
    component: React.FC;
  }[];
  config: {
    isAuth: boolean;
  };
}

export const routes: Routes[] = [
  {
    key: "statistics",
    name: "Statistics",
    path: "/statistics",
    component: Statistics,
    config: {
      isAuth: false,
    },
  },
  {
    key: "sportId",
    name: "SportId",
    path: "/:sportId",
    component: SportId,
    config: {
      isAuth: false,
    },
  },
  {
    key: "articleId",
    name: "ArticleId",
    path: "/article/:articleId",
    component: ArticleId,
    config: {
      isAuth: false,
    },
  },
  {
    key: "login",
    name: "Login",
    path: "/login",
    component: Login,
    config: {
      isAuth: false,
    },
  },
  {
    key: "dashboard",
    name: "Dashboard",
    path: "/",
    component: Dashboard,
    config: {
      isAuth: true,
    },
  },
  {
    key: "search",
    name: "Search",
    path: "/search",
    component: Search,
    config: {
      isAuth: true,
    },
  },
  {
    key: "insights",
    name: "Insights",
    path: "/insights",
    component: Insights,
    config: {
      isAuth: true,
    },
  },
  {
    key: "docs",
    name: "Docs",
    path: "/docs",
    component: Docs,
    config: {
      isAuth: true,
    },
  },
];

export const landing_routes: Routes = {
  key: "landing",
  name: "Landing",
  path: "/",
  component: LandingLayout,
  config: {
    isAuth: false,
  },
  children: [
    {
      key: "homePage",
      name: "HomePage",
      path: "/",
      component: Home,
    },
    {
      key: "blog",
      name: "Blog",
      path: "/blog",
      component: Blog,
    },
    {
      key: "contacts",
      name: "Contacts",
      path: "/contacts",
      component: Contacts,
    },
    {
      key: "support",
      name: "Support",
      path: "/support",
      component: Support,
    },
  ],
};
