import {
  Box,
  Button,
  Flex,
  Group,
  LoadingOverlay,
  TextInput,
} from "@mantine/core";
import { isNotEmpty, useForm } from "@mantine/form";
import { motion } from "framer-motion";
import { ArrowLeft } from "lucide-react";
import { useLogin } from "modules/auth/hooks/useLogin";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const { isLogin, login } = useLogin();
  const navigate = useNavigate();
  const form = useForm({
    mode: "uncontrolled",
    initialValues: {
      username: "kminchelle",
      password: "0lelplR",
    },

    validate: {
      username: isNotEmpty("Invalid email"),
      // email: (value) => (/^\S+@\S+$/.test(value) ? null : "Invalid email"),
      password: isNotEmpty("Please enter password"),
    },
  });

  return (
    <motion.div
      className="absolute w-full"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div
        className="bg-cover bg-no-repeat bg-[50%] bg-[url('https://hr.onecruit.uz/static/media/login_bg.dc5f0101e0d66bb0eee2.jpg')]"
        style={{
          width: "100%",
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          data-aos="flip-right"
          w={400}
          className="relative px-10 bg-white rounded-xl py-7 shadow-md"
        >
          <LoadingOverlay
            visible={isLogin}
            zIndex={1000}
            overlayProps={{ radius: "sm", blur: 2 }}
          />
          <Flex align={"center"} className="mb-3" justify={"center"}>
            <h2 className="text-2xl font-bold">Login</h2>
          </Flex>
          <form onSubmit={form.onSubmit((values) => login(values))}>
            <Flex
              gap="md"
              justify="flex-start"
              align="flex-start"
              direction="column"
              wrap="wrap"
            >
              <TextInput
                className="w-full"
                label="Email"
                placeholder="your@email.com"
                {...form.getInputProps("username")}
              />

              <TextInput
                className="w-full"
                label="Password"
                placeholder="******"
                {...form.getInputProps("password")}
              />
            </Flex>

            <Group justify="flex-end" mt="md">
              <Button
                onClick={() => navigate("/")}
                variant="default"
                leftSection={<ArrowLeft size={16} />}
              >
                Back
              </Button>
              <Button type="submit" loading={isLogin}>
                Submit
              </Button>
            </Group>
          </form>
        </Box>
      </div>
    </motion.div>
  );
};

export default Login;
