import {
  AppShell,
  Avatar,
  Box,
  Burger,
  Button,
  Divider,
  Flex,
  Group,
  Skeleton,
  Text,
  UnstyledButton,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { modals } from "@mantine/modals";
import { MantineLogo } from "@mantinex/mantine-logo";
import { Bolt, LogOut } from "lucide-react";
import React, { Fragment } from "react";
import { useDispatch } from "react-redux";
import { NavLink, Outlet, useNavigate } from "react-router-dom";
import { LOG_OUT } from "../../redux/actionTypes";
import { motion } from "framer-motion";
// import "./MobileNavbar.css";

const AppLayout = () => {
  const [mobileOpened, { toggle: toggleMobile }] = useDisclosure();
  const [desktopOpened, { toggle: toggleDesktop }] = useDisclosure(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    modals.openConfirmModal({
      title: "Please confirm your action",
      children: <Text size="md">Do you want to log out?</Text>,
      labels: { confirm: "Confirm", cancel: "Cancel" },
      onCancel: () => console.log("Cancel"),
      onConfirm: () => {
        dispatch({
          type: LOG_OUT,
        });
      },
    });
    navigate("/");
  };

  return (
    <Fragment>
      <motion.div
        className="absolute w-full"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <AppShell
          header={{ height: 70 }}
          navbar={{
            width: 300,
            breakpoint: "sm",
            collapsed: { mobile: !mobileOpened, desktop: !desktopOpened },
          }}
          padding="md"
        >
          <AppShell.Header>
            <Group h="100%" px="md">
              {/* <Burger
            opened={mobileOpened}
            onClick={toggleMobile}
            hiddenFrom="sm"
            size="sm"
          /> */}
              <Group justify="space-between" style={{ flex: 1 }}>
                <Flex align={"center"}>
                  <Burger
                    opened={mobileOpened}
                    onClick={toggleMobile}
                    hiddenFrom="sm"
                    size="sm"
                  />
                  <Burger
                    opened={desktopOpened}
                    onClick={toggleDesktop}
                    visibleFrom="sm"
                    size="sm"
                  />
                  <a className="flex items-center w-full px-3" href="#">
                    <svg
                      className="w-8 h-8 fill-current"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path d="M11 17a1 1 0 001.447.894l4-2A1 1 0 0017 15V9.236a1 1 0 00-1.447-.894l-4 2a1 1 0 00-.553.894V17zM15.211 6.276a1 1 0 000-1.788l-4.764-2.382a1 1 0 00-.894 0L4.789 4.488a1 1 0 000 1.788l4.764 2.382a1 1 0 00.894 0l4.764-2.382zM4.447 8.342A1 1 0 003 9.236V15a1 1 0 00.553.894l4 2A1 1 0 009 17v-5.764a1 1 0 00-.553-.894l-4-2z" />
                    </svg>
                    <span className="ml-2 text-sm font-bold">The App</span>
                  </a>
                  {/* <MantineLogo size={30}>a</MantineLogo> */}
                </Flex>
                <Group ml="xl" gap={0} className="py-4">
                  <Avatar
                    size="md"
                    src="https://raw.githubusercontent.com/mantinedev/mantine/master/.demo/avatars/avatar-1.png"
                  />
                </Group>
              </Group>
            </Group>
          </AppShell.Header>
          <AppShell.Navbar p={5}>
            <div className="flex flex-col items-center w-full h-full overflow-hidden   rounded">
              {/* <a className="flex items-center w-full px-3 mt-3" href="#">
            <svg
              className="w-8 h-8 fill-current"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path d="M11 17a1 1 0 001.447.894l4-2A1 1 0 0017 15V9.236a1 1 0 00-1.447-.894l-4 2a1 1 0 00-.553.894V17zM15.211 6.276a1 1 0 000-1.788l-4.764-2.382a1 1 0 00-.894 0L4.789 4.488a1 1 0 000 1.788l4.764 2.382a1 1 0 00.894 0l4.764-2.382zM4.447 8.342A1 1 0 003 9.236V15a1 1 0 00.553.894l4 2A1 1 0 009 17v-5.764a1 1 0 00-.553-.894l-4-2z" />
            </svg>
            <span className="ml-2 text-sm font-bold">The App</span>
          </a> */}
              <div className="w-full px-2">
                <div className="flex flex-col items-center w-full ">
                  <NavLink
                    to={"/"}
                    className="myNavLin flex items-center w-full h-12 px-3 mt-2 rounded "
                  >
                    <svg
                      className="w-6 h-6 stroke-current"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
                      />
                    </svg>
                    <span className="ml-2 text-sm font-medium">Dasboard</span>
                  </NavLink>
                  <NavLink
                    to={"/search"}
                    className="myNavLin flex items-center w-full h-12 px-3 mt-2 rounded "
                  >
                    <svg
                      className="w-6 h-6 stroke-current"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                      />
                    </svg>
                    <span className="ml-2 text-sm font-medium">Search</span>
                  </NavLink>
                  <NavLink
                    to={"/insights"}
                    className="myNavLin flex items-center w-full h-12 px-3 mt-2  rounded"
                  >
                    <svg
                      className="w-6 h-6 stroke-current"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M16 8v8m-4-5v5m-4-2v2m-2 4h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
                      />
                    </svg>
                    <span className="ml-2 text-sm font-medium">Insights</span>
                  </NavLink>
                  <NavLink
                    to={"/docs"}
                    className="myNavLin flex items-center w-full h-12 px-3 mt-2 rounded "
                  >
                    <svg
                      className="w-6 h-6 stroke-current"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M8 7v8a2 2 0 002 2h6M8 7V5a2 2 0 012-2h4.586a1 1 0 01.707.293l4.414 4.414a1 1 0 01.293.707V15a2 2 0 01-2 2h-2M8 7H6a2 2 0 00-2 2v10a2 2 0 002 2h8a2 2 0 002-2v-2"
                      />
                    </svg>
                    <span className="ml-2 text-sm font-medium">Docs</span>
                  </NavLink>
                </div>
              </div>
              <Box className="mt-auto w-full pb-2 px-2">
                <Button
                  className="shadow-md"
                  onClick={handleLogout}
                  leftSection={<LogOut size={14} />}
                  variant="light"
                  color="red"
                  fullWidth
                >
                  Log out
                </Button>
              </Box>
              {/* <a className="flex items-center justify-center w-full h-16 mt-auto bg-gray-200 hover:bg-gray-300">
            <svg
              className="w-6 h-6 stroke-current"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
            <span className="ml-2 text-sm font-medium">Log out</span>
          </a> */}
            </div>
          </AppShell.Navbar>
          <AppShell.Main className="overflow-hidden">
            <div>
              <Outlet />
            </div>
          </AppShell.Main>
        </AppShell>
      </motion.div>
    </Fragment>
  );
};

export default AppLayout;
